// const SERVER_URL = "http://localhost:8080/user";
const SERVER_URL = "https://lieve-backend-nodejs-q4dxnjegtq-uc.a.run.app/user";
// "http://192.168.29.124:8080/user"
const IMG_URL = "";
const YOUR_GOOGLE_MAPS_API_KEY = "";
const INSTAGRAM_KEY = "";
const PointValue = 0.05;
const MinPoints = 200;
module.exports = {
  SERVER_URL,
  IMG_URL,
  YOUR_GOOGLE_MAPS_API_KEY,
  INSTAGRAM_KEY,
  MinPoints,
  PointValue,
};
